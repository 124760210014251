import { useMemo } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { UserMetadata } from "../firebase/converters/leaderboardUserMetadata";
import { getLeaderboardUserMetadata } from "../summaryUtils";

export const useUserMetadata = (userId?: string): [UserMetadata | null | undefined, boolean] => {
  const [leaderboardUserMetadata, isLeaderboardMetadataLoading] = useDocumentData<UserMetadata>(
    userId ? getLeaderboardUserMetadata(userId) : null,
  );

  return useMemo(
    () => [
      isLeaderboardMetadataLoading ? null : leaderboardUserMetadata,
      isLeaderboardMetadataLoading,
    ],
    [isLeaderboardMetadataLoading, leaderboardUserMetadata],
  );
};
