import { getAuth, getIdTokenResult, User } from "firebase/auth";
import {
  addDoc,
  collection,
  type CollectionReference,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useEffect, useMemo, useState } from "react";
import { firebaseApp } from "./firebaseConfig";

import { loadStripe, Stripe } from "@stripe/stripe-js";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useAuthState } from "react-firebase-hooks/auth";
import { AuthClaim } from "./components/admin/PermissionsManager";

let stripePromise: Stripe | null;

const initializeStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(
      "pk_live_51NZgfZFBIwpy1XnfOTt5RoMTKk8wKpZePnqMij7Yueakk7P2mXK7a1LP9T6onDAgGfLCztGlHOgP0WdA3GfStI1n00PprjApR0",
    );
  }
  return stripePromise;
};

// this has the side effect of creating the stripe user account and linking it locally if it doesn't exist
// TODO: make sure user email is verified if only email auth
// We don't want to allow users to create a stripe account without verifying their email
export async function createPortalLink() {
  const result = await httpsCallable(
    getFunctions(firebaseApp),
    "ext-firestore-stripe-payments-createPortalLink",
  )({
    returnUrl: "https://techdisc.com",
  });
  return result;
}

// this has the side effect of creating the stripe user account and linking it locally if it doesn't exist
// TODO: make sure user email is verified if only email auth
// We don't want to allow users to create a stripe account without verifying their email
export async function createCheckoutSession(
  uid: string,
  priceId: string = STORE_PRICE_MO_ID,
  trialDays?: number | null | undefined,
) {
  const store = getFirestore(firebaseApp);
  const path = "/stripe/data/customers/" + uid + "/checkout_sessions";
  const checkouts: CollectionReference = collection(store, path);
  const BF_PROMO = "promo_1QQCtfFBIwpy1Xnf0xO3h3hv"; // 15% off through Dec 7 2024
  const subscription_data = trialDays
    ? {
        trial_period_days: trialDays,
        trial_settings: {
          end_behavior: {
            missing_payment_method: "cancel",
          },
        },
      }
    : {};

  const checkoutSessionRef = await addDoc(checkouts, {
    price: priceId,
    success_url: window.location.origin,
    cancel_url: window.location.origin,
    payment_method_collection: "if_required",
    billing_address_collection: "auto",
    subscription_data,
    automatic_tax: true,
    allow_promotion_codes: true,
    // promotion_code: BF_PROMO,
  });

  // Wait for the CheckoutSession to get attached by the extension
  onSnapshot(checkoutSessionRef, async (snap) => {
    // @ts-ignore
    const { sessionId } = snap.data();
    if (sessionId) {
      // We have a session, let's redirect to Checkout
      // Init Stripe
      const stripe = await initializeStripe();
      stripe?.redirectToCheckout({ sessionId });
    }
  });
}

export enum PremiumFeature {
  NONE = "none", // this is the default value
  STORE = "store_features",
  PLAY = "play_features",
  PRO = "pro_features",
}

function getDominatingFeatures(feature: PremiumFeature): PremiumFeature[] {
  switch (feature) {
    case PremiumFeature.PLAY:
      return [PremiumFeature.PRO, PremiumFeature.STORE];
    case PremiumFeature.PRO:
      return [PremiumFeature.STORE];
    default:
      return [];
  }
}

export function hasPremiumFeature(entitlements: Entitlement[], feature: PremiumFeature): boolean {
  if (entitlements.includes(feature)) {
    return true;
  }

  return getDominatingFeatures(feature).some((f) => entitlements.includes(f));
}

export const STORE_PRICE_YR_ID = "price_1O5XfRFBIwpy1Xnf4pWGTAZ9";
export const STORE_PRICE_MO_ID = "price_1O5XSbFBIwpy1XnfDbKCaXfz";

export const PRO_PRICE_YR_ID = "price_1PyIVqFBIwpy1XnfbUYnPEfZ";
export const PRO_PRICE_MO_ID = "price_1PyIWsFBIwpy1XnfFUQyRmmo";

export const PLAY_PRICE_YR_ID = "price_1Q3lGtFBIwpy1Xnf9vqFt1J2";
export const PLAY_PRICE_MO_ID = "price_1Q3lGtFBIwpy1XnfVtZSMWeB";

export async function hasStoreFeatures(user: User): Promise<boolean> {
  const decodedToken = await getIdTokenResult(user, true);
  return decodedToken?.claims?.stripeRole == "store_features";
}

async function fetchTokenAndSetClaims(
  user: User,
  setClaims?: (value: { [p: string]: any }) => void,
  setPremiumStatus?: (value: PremiumFeature) => void,
) {
  const analytics = getAnalytics(firebaseApp);
  const decodedToken = await getIdTokenResult(user, true);
  // only set if it matches an enum value
  if (decodedToken) {
    setClaims?.(decodedToken.claims);
  }

  const { stripeRole, revenueCatEntitlements } = decodedToken?.claims as {
    stripeRole?: PremiumFeature;
    revenueCatEntitlements?: PremiumFeature[];
  };

  let foundRole: PremiumFeature | undefined = undefined;
  if (stripeRole) {
    if ([PremiumFeature.STORE, PremiumFeature.PRO, PremiumFeature.PLAY].includes(stripeRole)) {
      setPremiumStatus?.(stripeRole);
      foundRole = stripeRole;
    } else {
      const message = "(S) invalid premium status: " + stripeRole;
      logEvent(analytics, message);
      console.error(message);
    }
  }

  if (revenueCatEntitlements?.some((entitlement) => entitlement === PremiumFeature.STORE)) {
    setPremiumStatus?.(PremiumFeature.STORE);
    return;
  }

  if (foundRole === PremiumFeature.STORE) {
    return;
  }

  if (revenueCatEntitlements?.some((entitlement) => entitlement === PremiumFeature.PRO)) {
    setPremiumStatus?.(PremiumFeature.PRO);
    return;
  }

  if (revenueCatEntitlements?.some((entitlement) => entitlement === PremiumFeature.PLAY)) {
    setPremiumStatus?.(PremiumFeature.PLAY);
    return;
  }

  if (revenueCatEntitlements) {
    const message = "(RC) invalid premium status: " + revenueCatEntitlements.join(", ");
    logEvent(analytics, message);
    console.error(message);
  }
}

export function usePremiumStatus(user: User | undefined | null): PremiumFeature | undefined {
  const [premiumStatus, setPremiumStatus] = useState<PremiumFeature>(PremiumFeature.NONE);

  useEffect(() => {
    if (user) {
      fetchTokenAndSetClaims(user, undefined, setPremiumStatus);
    }
  }, [user, user?.refreshToken]);

  return premiumStatus;
}

export function useAuthClaims(): AuthClaim[] | undefined {
  const [user, userLoading, userError] = useAuthState(getAuth(firebaseApp));
  const [claims, setClaims] = useState<{ [key: string]: any } | undefined>();

  useEffect(() => {
    if (user) {
      fetchTokenAndSetClaims(user, setClaims, undefined);
    }
  }, [user, user?.refreshToken]);

  if (claims == undefined) {
    return undefined;
  }

  // the value for each claim must be truthy.  We use true for now.
  // later a given claim may have a value like "read" or "write"
  return Object.values(AuthClaim).filter((claim) => claims?.[claim]);
}
const analytics = getAnalytics(firebaseApp);

export type Entitlement = AuthClaim | PremiumFeature;

export function useEntitlements(user: User | undefined | null): Entitlement[] {
  const [entitlements, setEntitlements] = useState<Entitlement[]>([]);

  useEffect(() => {
    if (user) {
      const fetch = async () => {
        const decodedToken = await getIdTokenResult(user, true);
        const claims = decodedToken?.claims as unknown as AuthClaim[];
        const entitlements: Entitlement[] = Object.keys(claims).filter(
          (claim) => claim in AuthClaim,
        ) as Entitlement[];

        const { stripeRole, revenueCatEntitlements } = claims as {
          stripeRole?: PremiumFeature;
          revenueCatEntitlements?: PremiumFeature[];
        };

        if (stripeRole) {
          if (Object.values(PremiumFeature).includes(stripeRole)) {
            entitlements.push(stripeRole);
          } else {
            const message = "(S) invalid premium status: " + stripeRole;
            logEvent(analytics, message);
            console.error(message);
          }
        }

        if (revenueCatEntitlements?.length) {
          if (revenueCatEntitlements.some((entitlement) => entitlement === PremiumFeature.PRO)) {
            entitlements.push(PremiumFeature.PRO);
          }
          if (revenueCatEntitlements.some((entitlement) => entitlement === PremiumFeature.PLAY)) {
            entitlements.push(PremiumFeature.PLAY);
          }
          if (revenueCatEntitlements.some((entitlement) => entitlement === PremiumFeature.STORE)) {
            entitlements.push(PremiumFeature.STORE);
          }

          if (
            !revenueCatEntitlements.every((entitlement) =>
              Object.values(PremiumFeature).includes(entitlement as PremiumFeature),
            )
          ) {
            const message = "(RC) invalid premium status: " + revenueCatEntitlements.join(", ");
            logEvent(analytics, message);
            console.error(message);
          }
        }

        setEntitlements(entitlements);
      };
      fetch();
    }
  }, [user]);

  return useMemo(() => Array.from(new Set(entitlements)), [entitlements]);
}

export function useStripeClaim(): string | undefined {
  const [user, userLoading, userError] = useAuthState(getAuth(firebaseApp));
  const [claims, setClaims] = useState<{ [key: string]: any } | undefined>();

  useEffect(() => {
    if (user) {
      fetchTokenAndSetClaims(user, setClaims, undefined);
    }
  }, [user, user?.refreshToken]);

  return claims?.stripeRole;
}

export const MANAGE_SUBSCRIPTION_URL = "https://billing.stripe.com/p/login/6oEg0a0379EAgiQ8ww";
