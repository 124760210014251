import type { PlayEvent } from "@/types/events";
import { addEventStatus } from "@/utils/events";
import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";

export const playEventConverter: FirestoreDataConverter<PlayEvent> = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<PlayEvent>,
    options: SnapshotOptions,
  ): PlayEvent => {
    const data = snapshot.data(options);

    return addEventStatus({
      ...data,
      id: snapshot.id,
    }) as PlayEvent;
  },
  toFirestore: (modelObject: PlayEvent): DocumentData => {
    const { id, isLive, isPreview, isPast, ...rest } = modelObject;
    return rest;
  },
};
