import { getAdminUserRolesCollection, getUserAdminCollection } from "@/firebase/collections";
import type { UserRole } from "@/types/user";
import type { User } from "firebase/auth";
import { doc, getDoc, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";

/**
 * Do no use beyond it's use in useRole
 * @param user
 * @returns
 */
export const useUserRoles = (user: User | null | undefined) => {
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);

  useEffect(() => {
    if (!user || user.uid === "unknown") {
      return;
    }
    const fetchUserRoles = async () => {
      const userAdminDoc = await getDoc(doc(getUserAdminCollection(), user.uid));
      const userRolesDocs = await getDocs(getAdminUserRolesCollection());

      const roles = [];
      const userRoles = userRolesDocs.docs.map((doc) => doc.data());

      if (userAdminDoc.exists()) {
        const { roles: roleIds = [] } = userAdminDoc.data();

        for (const roleId of roleIds) {
          const role = userRoles.find((role) => role.id === roleId);
          if (role) {
            roles.push(role);
          }
        }
      }

      setUserRoles(roles.filter((role) => !role.external) ?? []);
    };
    fetchUserRoles();
  }, [setUserRoles, user]);

  return { userRoles };
};
