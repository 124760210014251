import { addEventStatus, type EventTiming } from "@/utils/events";
import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  type Timestamp,
} from "firebase/firestore";

export type LiveEventDocument = {
  name: string;
  startDate: Timestamp;
  endDate: Timestamp;
  previewDate: Timestamp;
};

export type LiveEvent = EventTiming &
  LiveEventDocument & {
    id: string;
  };

export const liveEventConverter: FirestoreDataConverter<LiveEvent> = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<LiveEventDocument>,
    options: SnapshotOptions,
  ): LiveEvent => {
    const data = snapshot.data(options);

    return addEventStatus({
      ...data,
      id: snapshot.id,
    }) as LiveEvent;
  },
  toFirestore: (modelObject: LiveEvent): LiveEventDocument => {
    const { id, ...rest } = modelObject;
    return rest;
  },
};
