import { fromFirestore, toFirestore } from "@/firebase/converters/converter";
import type { SimulatorLayout, SimulatorLayoutDocument } from "@/types/simulator";
import type { FirestoreDataConverter } from "firebase/firestore";

export const simulatorLayoutConverter: FirestoreDataConverter<
  SimulatorLayout,
  SimulatorLayoutDocument
> = {
  fromFirestore,
  toFirestore,
};
