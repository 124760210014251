import { isAfter, isBefore, isEqual } from "date-fns";
import type { Timestamp } from "firebase/firestore";

export interface EventTiming {
  previewDate?: Timestamp;
  startDate: Timestamp;
  endDate: Timestamp;
}

export const addEventStatus = <T extends EventTiming>(event: T) => {
  const now = new Date();
  const startDate = event.startDate.toDate();
  const endDate = event.endDate.toDate();
  const previewDate = event.previewDate?.toDate();

  const isLive = isAfter(now, startDate) && isBefore(now, endDate);
  const isPreview = previewDate && isAfter(now, previewDate) && isBefore(now, startDate);
  const isPast = isAfter(now, endDate) || isEqual(now, endDate);

  return {
    ...event,
    isLive,
    isPreview,
    isPast,
  };
};
