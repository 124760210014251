import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";
import {
  type StrokePlayLeaderboardEntry,
  type StrokePlayLeaderboardEntryDocument,
} from "../../model/leaderboard";

export const strokePlayLeaderboardEntryConverter: FirestoreDataConverter<
  StrokePlayLeaderboardEntry,
  StrokePlayLeaderboardEntryDocument
> = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<StrokePlayLeaderboardEntry>,
    options: SnapshotOptions,
  ): StrokePlayLeaderboardEntry => {
    const data = snapshot.data(options);

    return { ...data, id: snapshot.id } as StrokePlayLeaderboardEntry; // Adjust this mapping as necessary
  },
  toFirestore: (modelObject: StrokePlayLeaderboardEntry) => {
    const document: DocumentData = { ...modelObject };
    delete document.id;
    return document;
  },
};
