import { useMemo } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { StoreMetadata } from "../firebase/converters/leaderboardStoreMetadata";
import { getLeaderboardStoreMetadata, saveLeaderboardStoreMetadata } from "../summaryUtils";

export const useStoreMetadata = (userId?: string): [StoreMetadata | null | undefined, boolean] => {
  const [leaderboardMetadata, isLeaderboardMetadataLoading] = useDocumentData<StoreMetadata>(
    userId ? getLeaderboardStoreMetadata(userId) : null,
  );

  return useMemo(
    () => [isLeaderboardMetadataLoading ? null : leaderboardMetadata, isLeaderboardMetadataLoading],
    [isLeaderboardMetadataLoading, leaderboardMetadata],
  );
};

export const clearStoreAddress = async (userId: string) => {
  await saveLeaderboardStoreMetadata(
    userId,
    {
      address: { addressLines: [] },
      // @ts-ignore
      addressValidity: null,
    },
    false,
  );
};
