import { Add, Logout, ManageAccounts, Storefront, Subscriptions } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Popover,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import {
  AuthError,
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  isSignInWithEmailLink,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailLink,
  updatePassword,
  User,
} from "firebase/auth";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { useEffect, useState } from "react";
import StyledFirebaseAuth from "../analysis/StyledFirebaseAuth";
import { authUiConfig } from "../authUiConfig";
import { useGlobal } from "../components/providers/GlobalProvider";
import { firebaseApp } from "../firebaseConfig";
import useNotify from "../hooks/useNotify";
import { useUser } from "../hooks/useUser";
import { useUserMetadata } from "../hooks/useUserMetadata";
import { Handedness } from "../model/UserSettings";
import { getQueryMap, setQueryString } from "../queryUtils";
import { useStripeClaim } from "../stripe";
import { getTrueUserId, storeUserSettings } from "../summaryUtils";

export default function Account() {
  // const [reload, setReload] = useState(false);
  const notify = useNotify();
  const { isStore, isPremiumStore, userRolesLoading } = useGlobal();
  const firebaseAuth = getAuth(firebaseApp);
  const [{ user, userSettings }, userLoading] = useUser();
  const [userMetadata, profileLoading] = useUserMetadata(user?.uid ?? "no_iser");

  const [anchorEl, setAnchorEl] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showVerify, setShowVerify] = useState(false);

  useEffect(() => {
    let isCancelled = false;
    const email = user?.email;
    if (
      user &&
      email &&
      user.providerData.length === 1 &&
      user.providerData[0].providerId === "password"
    ) {
      (async () => {
        const methods = await fetchSignInMethodsForEmail(firebaseAuth, email);
        // methods can be "google.com", "apple.com", "password", or "emailLink"
        // if we only have "emailLink", we should show a password field so the user can set a password
        if (!isCancelled) {
          setShowPassword(methods.length === 1 && methods[0] == "emailLink");
          if (!user.emailVerified && methods.includes("password")) {
            setShowVerify(true);
          }
        }
      })();
    }
    return () => {
      isCancelled = true;
    };
  }, [user]);

  useEffect(() => {
    if (isSignInWithEmailLink(firebaseAuth, window.location.href)) {
      const emailString = getQueryMap().get("email");
      if (emailString) {
        handleSignInWithEmail(emailString, notify);
      }
      removeEmailLoginKeysFromUrl();
    }
  }, [window.location.href]);

  const stripeClaim = useStripeClaim();

  if (!user && !userLoading) {
    return <div>{createLoginPopover()}</div>;
  }
  if (user && !userLoading && !profileLoading) {
    return createAccountPopover(
      userSettings,
      user,
      anchorEl,
      setAnchorEl,
      showVerify,
      setShowVerify,
      showPassword,
      setShowPassword,
      isPremiumStore,
      isStore,
      userMetadata,
      userRolesLoading,
      notify,
      stripeClaim,
    );
  }
  return (
    <Avatar
      sx={{
        width: 32,
        height: 32,
      }}
    />
  );
}

export function LeftRightHandToggle(props: {
  handedness: Handedness;
  setHandedness?: (h: Handedness) => void;
  skipStorage?: boolean;
}) {
  return (
    <ToggleButtonGroup
      color="primary"
      value={props.handedness}
      exclusive
      onChange={(event) => {
        props.setHandedness?.(event.target.innerText as Handedness);
        if (!props.skipStorage) {
          storeUserSettings(getTrueUserId(), {
            handedness: event.target.innerText,
          });
        }
      }}
    >
      <ToggleButton value="LEFT" sx={{ py: "8px" }}>
        LEFT
      </ToggleButton>
      <ToggleButton value="RIGHT" sx={{ py: "8px" }}>
        RIGHT
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

function createAccountPopover(
  userSettings,
  user: User,
  anchorEl,
  setAnchorEl,
  showVerify,
  setShowVerify,
  showPassword,
  setShowPassword,
  isPremiumStore,
  isStore,
  userMetadata,
  userRolesLoading,
  notify,
  stripeClaim: string | undefined,
) {
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isStripeCustomer = !!stripeClaim;

  return (
    <div>
      <Avatar
        alt={userMetadata?.name ?? user.displayName ?? user.email}
        src={userMetadata?.photo || user.photoURL || "/no_photo.png"}
        onClick={handleClick}
        sx={{
          cursor: "pointer",
          width: 32,
          height: 32,
          backgroundColor: "white",
          color: "primary.main",
          border: "1px solid",
          borderColor: "primary.main",
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ ".MuiList-root": { minWidth: "175px" } }}
      >
        {showVerify && (
          <MenuItem>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                sendEmailVerification(user);
                setShowVerify(false);
              }}
            >
              Send Email Verification
            </Button>
          </MenuItem>
        )}
        {showVerify && (
          <Divider
            sx={{
              my: "10px",
            }}
          />
        )}
        {showPassword && !(user?.email ?? "").endsWith("gmail.com") && (
          // add a password field so the user can set a password
          <MenuItem
            sx={{
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0) !important",
              },
            }}
          >
            <form
              onSubmit={(event) => {
                event.preventDefault(); // Prevent form submission
                const password = event.target.elements.password.value;
                const credential = EmailAuthProvider.credential(user.email, password);
                updatePassword(user, password)
                  .then(() => {
                    notify("success", "Password updated successfully");
                    setShowPassword(false);
                  })
                  .catch((error) => {
                    console.error("Password store error", error);
                    if (error.code === "auth/weak-password") {
                      notify("error", "Password is too weak");
                      return;
                    }

                    notify("error", "Failed to store password, sending password reset email");
                    setShowPassword(false);
                    sendPasswordResetEmail(getAuth(firebaseApp), user.email).catch((error) => {
                      console.error("Failed to send password reset email", error);
                    });
                  });
              }}
            >
              <TextField
                id="password"
                label="Password"
                type="password"
                autoComplete="current-password"
                variant="standard"
                sx={{
                  width: "100%",
                }}
              />
              <hr />
              <Button variant="contained" color="primary" type="submit">
                Create Password
              </Button>
            </form>
          </MenuItem>
        )}
        {showPassword && (
          <Divider
            sx={{
              my: "10px",
            }}
          />
        )}
        <Stack
          direction="row"
          justifyContent="center"
          sx={{
            mb: "10px",
          }}
        >
          <LeftRightHandToggle handedness={userSettings?.handedness ?? Handedness.RIGHT} />
        </Stack>
        <Divider sx={{ my: "10px" }} />
        <Link
          href="/settings"
          underline="none"
          color="MenuText"
          sx={{
            "&:hover": { textDecoration: "none", color: (theme) => theme.palette.common.black },
          }}
        >
          <MenuItem>
            <ListItemIcon>
              <ManageAccounts sx={{ color: "grey.600", fontSize: "20px" }} />
            </ListItemIcon>
            Settings
          </MenuItem>
        </Link>
        {isStore && (
          <Link
            href="/store-management"
            underline="none"
            color="MenuText"
            sx={{
              "&:hover": { textDecoration: "none", color: (theme) => theme.palette.common.black },
            }}
          >
            <MenuItem>
              <ListItemIcon>
                <Storefront sx={{ color: "grey.600", fontSize: "20px" }} />
              </ListItemIcon>
              Store Management
            </MenuItem>
          </Link>
        )}

        <Link
          key="manage-plans"
          href="/plans"
          underline="none"
          color="MenuText"
          sx={{
            "&:hover": { textDecoration: "none", color: (theme) => theme.palette.common.black },
          }}
        >
          <MenuItem>
            {isStripeCustomer ? (
              <>
                <ListItemIcon>
                  <Subscriptions sx={{ color: "grey.600", fontSize: "20px" }} />
                </ListItemIcon>
                Manage your Plan
              </>
            ) : (
              <>
                <ListItemIcon>
                  <Add sx={{ color: "grey.600", fontSize: "20px" }} />
                </ListItemIcon>
                Upgrade your Plan
              </>
            )}
          </MenuItem>
        </Link>
        <Divider
          key="premiumDiv"
          sx={{
            my: "10px",
          }}
        />

        <MenuItem
          onClick={async () => {
            handleClose();
            await getAuth(firebaseApp).signOut();
          }}
        >
          <ListItemIcon>
            <Logout sx={{ color: "grey.600", fontSize: "20px" }} />
          </ListItemIcon>
          Sign Out
        </MenuItem>
      </Menu>
    </div>
  );
}

function removeEmailLoginKeysFromUrl() {
  const queryMap = getQueryMap();
  queryMap.delete("oobCode");
  queryMap.delete("mode");
  queryMap.delete("lang");
  queryMap.delete("apiKey");
  queryMap.delete("email");
  setQueryString(queryMap);
}

function handleSignInWithEmail(email: string, notify: ReturnType<typeof useNotify>) {
  signInWithEmailLink(getAuth(firebaseApp), email, window.location.href)
    .then((result) => {
      removeEmailLoginKeysFromUrl();
    })
    .catch((error: AuthError) => {
      if (
        error.code === "auth/user-token-expired" ||
        error.code === "auth/expired-action-code" ||
        error.code === "auth/invalid-action-code"
      ) {
        notify("error", "Login link expired.");
        removeEmailLoginKeysFromUrl();
      } else if (error.code === "auth/invalid-email") {
        notify("error", "Email does not match.");
      } else {
        console.error(error);
      }
    });
}

function EmailLoginPopover(props: object) {
  const notify = useNotify();
  const [email, setEmail] = useState("");
  const handleClick = () => {
    handleSignInWithEmail(email, notify);
  };
  return (
    <PopupState variant="popover" popupId="emailLoginPopover">
      {(popupState) => (
        <div>
          <Button variant="contained" style={{ background: "white" }} {...bindTrigger(popupState)}>
            <Typography color="primary" component="p" display="inline">
              {"Login"}
            </Typography>
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={true}
          >
            <div>
              <TextField
                id="email"
                label="Enter Email to login"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <Button variant="contained" color="primary" onClick={handleClick}>
                Submit
              </Button>
            </div>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}

function createLoginPopover() {
  return (
    <PopupState variant="popover" popupId="loginPopover">
      {(popupState) => (
        <div>
          <Button variant="outlined" size="small" {...bindTrigger(popupState)}>
            Login
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div style={{ width: "400px" }}>
              <StyledFirebaseAuth uiConfig={authUiConfig()} firebaseAuth={getAuth(firebaseApp)} />
              {/*<FirebaseAuth uiConfig={authUiConfig} firebaseAuth={getAuth(firebaseApp)} />*/}
            </div>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}
