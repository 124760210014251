import { DeviceReadings } from "./model/calibrate";

export const bleNusServiceUUID = "6e400001-b5a3-f393-e0a9-e50e24dcca9e";
export const bleNusCharRXUUID = "6e400002-b5a3-f393-e0a9-e50e24dcca9e";
export const bleNusCharTXUUID = "6e400003-b5a3-f393-e0a9-e50e24dcca9e";
export const bleBatteryService = "battery_service";
export const bleBatteryLevelChar = "battery_level";
export const deviceInfoService = 0x180a;
export const hardwareRevision = 0x2a27;
export const softwareRevision = 0x2a28;
export const cyOtaUpgradeServiceUuid = "00060000-f8ce-11e4-abf4-0002a5d5c51b";
export const cyOtaUpgradeCommandUuid = "00060001-f8ce-11e4-abf4-0002a5d5c51b";
export const calibrateServiceUuid = "ae3dc67e-a182-48e1-9b65-8ece7c00f896";
export const calibrateAccel0Uuid = "ae3dc67e-a182-48e1-9b65-8ece7c00f897";
export const calibrateAccel1Uuid = "ae3dc67e-a182-48e1-9b65-8ece7c00f898";
export const calibrateAccel2Uuid = "ae3dc67e-a182-48e1-9b65-8ece7c00f899";
export const calibrateGyroUuid = "ae3dc67e-a182-48e1-9b65-8ece7c00f89a";
export const calibrateQuaternionUuid = "ae3dc67e-a182-48e1-9b65-8ece7c00f89b";
export const calibrateMacUuid = "ae3dc67e-a182-48e1-9b65-8ece7c00f89c";
export const calibrateAccel1FloatUuid = "ae3dc67e-a182-48e1-9b65-8ece7c00f89d";
export const calibrateAccel2FloatUuid = "ae3dc67e-a182-48e1-9b65-8ece7c00f89e";
export const calibrateMissedSpi = "ae3dc67e-a182-48e1-9b65-8ece7c00f89f";
export const calibrateAccelNed = "ae3dc67e-a182-48e1-9b65-8ece7c00f8a0";
export const calibrateErrorRx = "ae3dc67e-a182-48e1-9b65-8ece7c00f8a1";
export const calibrateSampleTime = "ae3dc67e-a182-48e1-9b65-8ece7c00f8a2";
export const calibrateLoopTime = "ae3dc67e-a182-48e1-9b65-8ece7c00f8a3";
export const calibrateButton = "ae3dc67e-a182-48e1-9b65-8ece7c00f8a4";
export const calibrateDebugNumbers = "ae3dc67e-a182-48e1-9b65-8ece7c00f8a5";
export const calibrateDebugVectors = "ae3dc67e-a182-48e1-9b65-8ece7c00f8a6";
export const calibrateDeviceUid = "ae3dc67e-a182-48e1-9b65-8ece7c00f8a7";

export const calibrationKeyNames = ["x", "y", "negX", "negY", "z", "negZ"];
export const sensorsGravityStandard = 9.80665;
export const sensorsDpsToRads = 0.017453293;

export const NUM_POINTS = 2000;
export const DATA_LEN_V5 = 26 * NUM_POINTS + 28; // 52028
export const DATA_LEN_V6 = 53076;
export const VERSION_CRC = 3;
export const VERSION_START_Q = 4;
export const VERSION_8K_DPS = 5;
export const VERSION_MAG = 6;
export const MAX_FORMAT_VERSION = VERSION_MAG;
export const MAX_HARDWARE_VERSION = 17;
export const CRC_SKIP_BYTES = 6;
export const DELAY_MS_BEFORE_FETCH_FLIGHT = 1250;

export function isCalibrationReady(readings: DeviceReadings) {
  return calibrationKeyNames.reduce((acc, key, index, array) => {
    return acc && readings.accel0[key] != null;
  }, true);
}
